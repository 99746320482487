/**
 * Configuration by exports values per environment, as set by REACT_APP_STAGE
 */
import {isArray, merge, mergeWith, union, uniq} from "lodash";
import {loadClientConfig} from "up-form";
export const {name, version} = require("../package.json");
export const stage = process.env.REACT_APP_STAGE || "unknown";
export const isDev = process.env.REACT_APP_STAGE !== "production";
export const buildId = process.env.REACT_APP_BUILD_ID;
export const branch = process.env.REACT_APP_BRANCH_NAME;
export const commit = process.env.REACT_APP_COMMIT;
export const publicUrl = process.env.PUBLIC_URL || window.location.origin;
export const clientConfigKey = `config:${name}:${version}`;

// Allow build-time override from variable
const upApiOverride = process.env.REACT_APP_UP_API_URI;

// Top level fallbacks
const defaultConfig = {
  analytics: {
    appName: process.env.REACT_APP_APP_NAME,
    googleAnalyticsId: "GTM-K539HFQ",
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
    trackEvents: {
      blacklist: /@@redux-form\/(TOUCH|UNTOUCH|FOCUS|BLUR)/ // remove some of the highest volume events
    }
  },
  global: {
    buildId,
    branch,
    stage,
    commit,
    isDev,
    favicon: "/providers/up/up-education-logo.svg",
    google: {
      apiKey: "AIzaSyBdS0kbi8NqHmmUcfFRrNyXgD85ikNuQvo"
    },
    paypal: {
      // global default for dev work - will be overridden by providers
      clientId:
        "AXJGPrfGkqwe1QzLVpQ3fpa847qKX1ZBAFZAydo8xjV_GrtU_bU9UKKVLiYFgo5tNS3pbl1QiUx215iA"
    },
    links: {
      homeUrl: "http://up.education",
      privacy:
        "https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf",
      cookie:
        "https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf",
      terms:
        "https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf"
    },
    component: {
      // Component configuration - nested by folder/react component name
      app: {
        App: {
          defaultStep: 0, // Default step to start form on when starting from scratch
          // resumeStep: 2, // Default step to start form on when starting from scratch (undefined = as far as possible)
          persist: false
        }
      },
      // Not component name - field visibility within secionts
      Optional: {
        exclude: [
          // these are excluded by default and get opted in (fields for new providers only will typically get added here so backward compat is preserved)
          "Education.currentNzqaYearLevel",
          "Education.highestCompletedSchoolLevel",
          "Education.highestQualification.required",
          "Education.nsiNumber",
          "Education.previousOccupation.required",
          "Education.lastYearOfSchool.required",
          "Education.usiNumber",
          "Education.yearExpectToCompleteAcademicReqmts",
          "Education.yearExpectToCompleteAcademicReqmts.required",
          "Health.assistanceRequiredInEmergency",
          "Health.medicalConditionsAndMedication",
          "History.criminalConvictions",
          "Personal.applicantType",
          "StudentDetails.international",
          // Whole sections
          "DomesticEnrolmentForm.step.personal.language",
          "DomesticEnrolmentForm.step.personal.residencyStatusAU", // Country oriented citizenship
          "DomesticEnrolmentForm.step.personal.residencyStatusNZ",
          "DomesticEnrolmentForm.step.education.qualifications", // and qualifications
          // Whole steps
          "DomesticEnrolmentForm.step.checkout", // only yoobee SC doing payment to start with
          "ChooseIntake.dropdown",
          "Personal.irdNumber"
        ]
      },
      form: {
        DomesticEnrolmentForm: {
          clearAfterSubmit: true
        }
      },
      section: {
        Address: {autoComplete: true},
        ChooseIntake: {intakeOrientationThreshold: 6}, // If number of intakes in a single year exceed this, display choices vertically
        Documents: {
          accept: [
            ".doc",
            ".docx",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".pdf",
            "application/pdf",
            ".png",
            ".jpg",
            ".jpeg",
            "image/*"
          ],
          maxFileSize: "32Mb"
        },
        Education: {checkFeesFree: false},
        StudentDetails: {
          checkPhoneCountries: ["NZ"]
        }
      }
    }
  },
  upApi: {
    scaffold: {
      all: []
    }
  }
};

// Defaults for merging in for each group a provider belongs to
export const providerGroups = {
  // By Country
  au: {
    global: {
      component: {
        Optional: {
          include: [
            "Education.highestCompletedSchoolLevel",
            "DomesticEnrolmentForm.step.personal.language",
            "DomesticEnrolmentForm.step.personal.residencyStatusAU",
            "DomesticEnrolmentForm.step.education.qualifications" // AU  opts in for seperate qualifications rather than tertiary info
          ],
          exclude: [
            "Education.firstTertiary", // tertiary institutions nz specific
            "Education.nzHighSchool", // no point in asking for nz school in au
            "Education.highestQualification" // is nz specific
          ]
        },
        section: {
          Address: {
            preferCountry: "AU"
          },
          StudentDetails: {
            checkPhoneCountries: ["AU"]
          }
        }
      }
    }
  },
  nz: {
    global: {
      component: {
        Optional: {
          exclude: [
            "Address.state",
            "DomesticEnrolmentForm.step.education.employment"
          ],
          include: [
            "Education.nsiNumber",
            "DomesticEnrolmentForm.step.personal.residencyStatusNZ"
          ]
        },
        section: {
          Address: {
            preferCountry: "NZ"
          }
        }
      }
    }
  },

  // By Ownership
  "yoobee-group": {
    global: {
      groups: ["nz"],
      links: {
        handbook:
          "https://www.yoobee.ac.nz/media/2079/student_handbook_yoobee_2019_web.pdf",
        home: "http://yoobee.ac.nz",
        terms: "/providers/yoobee/yoobee-terms-conditions.pdf",
        expired: "https://www.yoobee.ac.nz/",
        youthGuarantee:
          "/providers/yoobee-group/Youth Guarantee Declaration NZST.pdf"
      },
      favicon: "/providers/yoobee-short-courses/favicon.ico",
      logo: "/providers/yoobee/Yoobee_Master_Lockup_01_BLACK_03_22.png",
      logoBg: "#FAFAFA"
    }
  },
  "nzma-group": {
    global: {
      groups: ["nz"],
      component: {
        Optional: {
          include: ["History.criminalConvictions"]
        }
      },
      links: {
        handbook:
          "/providers/nzma-group/NZMA_Student_Handbook_2024-February.pdf",
        terms: "/providers/nzma-group/nzma-group-terms-and-conditions.pdf",
        youthGuarantee:
          "/providers/nzma-group/Youth Guarantee Declaration NZMA.pdf"
      }
    }
  }
};

// Defaults for each provider
export const providers = {
  "up-online": {
    global: {
      groups: ["nz"],
      component: {
        Optional: {
          include: ["Personal.irdNumber"]
        }
      },
      links: {
        home: "http://up.education",
        terms:
          "https://www.up.education/content/uploads/2019/08/UPIC010_Privacy-Policy_SCREEN.pdf"
      },
      logo: "/providers/up/up-education-logo.svg",
      logoBg: "#FFF"
    }
  },
  hanz: {
    global: {
      groups: ["yoobee-group"],
      component: {
        Optional: {
          include: ["Personal.irdNumber"]
        }
      },
      logo: "/providers/hanz/HANZ-Logo-Lockup-BLACK.svg",
      logoBg: "#ab91ce",
      links: {
        handbook: "/providers/hanz/Student_Handbook_2025_v3.pdf",
        terms: "/providers/hanz/Terms and Conditions - Yoobee.pdf"
      }
    }
  },
  yoobee: {
    global: {
      groups: ["yoobee-group"],
      component: {
        Optional: {
          include: ["Personal.irdNumber"]
        }
      },
      links: {
        handbook: "/providers/yoobee/Student_Handbook_2025_v3.pdf",
      }
    }
  },
  "yoobee-short-courses": {
    global: {
      component: {
        Optional: {
          include: [
            "DomesticEnrolmentForm.step.checkout",
            "StudentDetails.international",
            "Education.currentNzqaYearLevel",
            "Education.highestQualification.required",
            "Education.lastYearOfSchool.required",
            "Education.previousOccupation.required",
            "Education.yearExpectToCompleteAcademicReqmts",
            "Education.yearExpectToCompleteAcademicReqmts.required",
            "Personal.applicantType",
            "Personal.irdNumber"
          ],
          exclude: [
            "Declaration.payment",
            "DomesticEnrolmentForm.step.education.employment",
            "Education.firstTertiary"
          ]
        }
      },
      groups: ["yoobee-group"],
      links: {
        handbook:
          "https://www.yoobee.ac.nz/media/3253/student-handbook-yoobee-short-courses.pdf",
        terms:
          "https://assets.ctfassets.net/wdk97frkc44n/DqQf80rhQmyMe4f6xCDpC/bb2d7d7e953b0115c4cda01d591fd494/terms-and-conditions-yoobee-short-courses.pdf "
      },
      paypal: {
        clientId: isDev
          ? "ARDVZkS8J6caA9YCBIm3lp5zqojNTrPitNoBPA3MRpHVSSXHnm5VPP_3LntY6wwJT6HXaGUi1PTPgBlj" // payables-facilitator@yoobee.ac.nz API: YoobeeSiteShortCourse
          : "AVpUea7v7st7xWEHKLlPpl3ASUWy18e8ObVEM2PhLnfj5S3Vx3M1D-9trfLfm_0wFMafvY9nVeU9G1YX" // account: payables@yoobee.ac.nz, API: YoobeeSiteShortCourse
      }
    }
  },
  nzst: {
    global: {
      groups: ["yoobee-group"],
      component: {
        Optional: {
          include: ["Personal.irdNumber"]
        }
      },
      links: {
        handbook:"/providers/nzst/Student_Handbook_2025_v3.pdf",
        home: "http://nzschooloftourism.co.nz",
        expired: "https://nzschooloftourism.co.nz/enrol/"
      },
      logo: "/providers/nzst/nzst-logo.png",
      logoBg: "#111111"
    }
  },
  nzma: {
    global: {
      groups: ["nzma-group"],
      component: {
        Optional: {
          include: ["Personal.irdNumber"]
        }
      },
      links: {
        home: "https://www.nzma.ac.nz/",
        expired: "https://www.nzma.ac.nz/enrol"
      },
      logo: "/providers/nzma/nzma-logo.png",
      logoBg: "#FFF"
    }
  },
  "cut-above": {
    global: {
      groups: ["nz", "yoobee-group"],
      component: {
        Optional: {
          include: ["Personal.irdNumber"]
        }
      },
      links: {
        handbook:"/providers/cut-above/Student_Handbook_2025_v3.pdf",
        home: "https://www.cutabove.ac.nz/",
        expired: "https://www.cutabove.ac.nz/",
        terms: "/providers/cut-above/cut-above-terms-and-conditions.pdf"
      },
      logo: "/providers/cut-above/cut-above-logo.png",
      logoBg: "#000000"
    }
  },
  elite: {
    global: {
      groups: ["yoobee-group"],
      component: {
        Optional: {
          include: ["Personal.irdNumber"]
        }
      },
      links: {
        handbook: "/providers/elite/Student_Handbook_2025_v3.pdf",
        home: "https://www.elitebeautyschool.co.nz/",
        elite: "https://www.elitebeautyschool.co.nz/"
      },
      logo: "/providers/elite/elite-logo.png",
      logoBg: "#333333"
    }
  },
  "culinary-collective": {
    global: {
      groups: ["nzma-group"],
      component: {
        Optional: {
          include: ["Personal.irdNumber"]
        }
      },
      links: {
        home: "https://culinarycollective.co.nz",
        expired: "https://www.culinarycollective.co.nz/apply"
      },
      logo: "/providers/tcc/tcc-logo.png",
      logoBg: "#FFF"
    }
  },
  "nz-institute-of-sport": {
    global: {
      groups: ["nzma-group"],
      component: {
        Optional: {
          include: ["Personal.irdNumber"]
        }
      },
      links: {
        home: "https://www.nzis.co.nz/",
        expired: "https://www.nzis.co.nz/application-form/"
      },
      logo: "/providers/nzis/nzis-logo.png",
      logoBg: "#FFF"
    }
  },
  ichm: {
    global: {
      groups: ["au"],
      component: {
        Optional: {
          include: ["Health.otherMedicalDetails"],
          exclude: [
            "ChooseCourse.previouslyStudied",
            "Declaration.payment",
            "Declaration.terms",
            "Declaration.declaration",
            "Education.previousOccupation",
            "Employment.occupation",
            "Employment.industry",
            "Health.disability",
            "Health.medicalConditionsAndMedication",
            "Health.assistanceRequiredInEmergency",
            "Language.spokenEnglish",
            "Language.requiresEnglishHelp",
            "Language.requiresLanguageLiteracyNumeracyHelp"
          ]
        }
      },
      links: {
        expired: "https://www.ichm.edu.au",
        fees: "https://www.ichm.edu.au/future-students/ichm-fees/fees-2022",
        handbook: "https://www.ichm.edu.au",
        home: "https://www.ichm.edu.au/",
        refund: "https://www.ichm.edu.au/__files/f/20176/Fee_and_Refund.pdf"
      },
      favicon: "/providers/ichm/ichm-logo.svg",
      logo: "/providers/ichm/ichm-logo.svg",
      logoBg: "#000070"
    }
  },
  "new-zealand-tertiary-college": {
    global: {
      groups: ["nzma-group"],
      component: {
        Optional: {
          include:
          [
            "ChooseIntake.dropdown",
            "Personal.irdNumber"
          ],
          exclude: ["ChooseIntake.toogleButton"]
        }
      },
      links: {
        home: "https://www.nztertiarycollege.ac.nz/",
        expired: "https://apply.nztertiarycollege.ac.nz/",
        terms: "/providers/nztc/terms-and-conditions-nztc.pdf"
      },
      logo: "/providers/nztc/nztc-logo.png",
      logoBg: "#FFF"
    }
  }
};

// Defaults for development stages
export const stages = {
  // Use these values if stage is not specified
  unknown: {},

  // Stage specific
  local: {
    analytics: {
      googleAnalyticsId: "GTM-PRBCKLS",
      debug: 1
    },
    upApi: {
      baseUri: upApiOverride || "http://localhost:3030/",
      scaffold: {
        stageOnly: []
      }
    },
    global: {
      google: {
        apiKey: "AIzaSyCe2jT8Fs6xhGy2WDPb5Cke7cLYix11w1w"
      }
    }
  },
  development: {
    analytics: {
      googleAnalyticsId: "GTM-PRBCKLS",
      debug: 1
    },
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-dev.azure-api.net/"
    }
  },
  test: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-test.azure-api.net/"
    }
  },
  uat: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-uat.azure-api.net/"
    },
    global: {
      component: {
        Education: {checkFeesFree: false}
      }
    }
  },
  production: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-prod.azure-api.net/"
    },
    global: {
      component: {
        Education: {checkFeesFree: false}
      }
    }
    //TBD
  }
};

// Find which provider to view and grab its config
const providerConfig = [
  // tuples of regexp to match location url with  and funtion to take match result and return provider slug if matched
  [/\/\/localhost.*/, () => process.env.REACT_APP_PROVIDER], // local host will provide a providerSlug as env
  [/\/\/apply(?:-\w+)?\.(ichm)(?:-\w+)?\.edu\.au(?::\d+)?/, (m) => m[1]], // Provider in host
  [
    /\/\/(?:.*?)?\/([\w-]+)(?:\/.*)?/,
    (m) => m[1] // Provider in path
  ]
]
  .map(([patt, toSlug]) => {
    const m = window.location.href.match(patt);
    return m && toSlug(m);
  })
  .map(
    (providerSlug) =>
      providerSlug &&
      providers[providerSlug] && {
        ...merge({global: {providerSlug}}, providers[providerSlug])
      }
  )
  .find((provider) => !!provider);
console.debug("Provider config:", providerConfig);

// When we merge configs, arrays are alwayd unioned, objects override ifs keys match
function customizer(obj, src) {
  if (isArray(obj)) return union(obj, src); //merge arrays (e.g. exclude,include) as sets
}

/**
 * Return a provider config, with group membership config recursively merged in
 * @param {*} providerConfig
 * @returns
 */
export function providerWithGroups(providerConfig = {}) {
  function descendentGroups(group) {
    const {global: {groups: children = []} = {}} = providerGroups[group];
    return [group, ...children.flatMap((group) => descendentGroups(group))];
  }
  const {global: {groups = []} = {}} = providerConfig;
  const descendants = uniq(groups.flatMap((group) => descendentGroups(group)));
  return mergeWith({}, ...descendants.map((group) => providerGroups[group] || {}), providerConfig, customizer);
}

// Calculate base config
export const baseConfig = mergeWith({}, defaultConfig, providerWithGroups(providerConfig), stages[stage], customizer);

// Merge over persisted settings from local storage in dev
const config = isDev ? mergeWith({}, baseConfig, loadClientConfig(clientConfigKey), customizer) : baseConfig;

console.debug("Computed config:", config);
export default config;
